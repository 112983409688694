@import url("https://fonts.googleapis.com/css2?family=Smooch&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");

.display-info {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 6;
  position: relative;
}

.intro-vid {
  width: 100%;
  height: 100%;
}

.video-only-div {
  width: 100%;
}

.video-data {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.name-work-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: RGB(0, 0, 0, 0);
  margin-top: -50%;
  margin-top: -40%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
.display-name {
  font-family: "Smooch", cursive;
  font-size: 100px;
  font-size: 130px;
  background-color: RGB(0, 0, 0, 0);

  z-index: 7;
}
.display-stack {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 700;
  z-index: 7;
}

.display-stack::before {
  content: "";
  width: 50px;
  height: 50px;
  background-color: #ff006e;
  border-top: 2px solid #ff006e;

  border-left: 2px solid #ff006e;
}

.display-stack::after {
  content: "";
  width: 100px;
  height: 100px;
  background-color: brown;
  border-top: 2px solid red;
  border-left: 2px solid red;
}

@media screen and (max-width: 800px) {
  .display-name {
    font-size: 50px;
  }
}


@media screen and (max-width: 790px) {
 
.intro-vid{
  height: 100%;
}
 
 }