/* @font-face {
  font-family: 'Hk Grotesk';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/HKGrotesk-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Jost-Regular.ttf') format('woff');
} */


*{
  padding:0;
  margin: 0;
  box-sizing: inherit;
/*   
  background-color: #010606; */
  /* background-color: black; */
  color: white;

}

body {
  background-color: black;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size-small);
  font-family: var(--font-stack);
  font-weight: 400;
  color: var(--white-1);
  line-height: var(--line-height-normal);
  background: var(--black);
  overflow-x: hidden;
}
/* h1,
h2,
h3 {
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    line-height: var(--line-height-small);
    color: var(--white);
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
