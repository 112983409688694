.navbar {
  display: flex;
  height: 10vh;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  bottom: 0px;
  z-index: 9;
  position: fixed;
  background-color: red;
  background-color: #ff006e;
  background-color: #bdb2ff;
  background-color: rgba(106, 4, 15, 0.5);
}

.navbar.active {
  background-color: transparent;
}
.navbarspan {
  font-size: larger;
  color: #d00000;
  color: #ff006e;

  text-decoration: none;
  background-color: none;
  transition: font-size 0.5s;
}

.navbarspan-larger {
  font-size: 50px;
  background-color: none;
}

.navbarspan:hover {
  font-size: 40px;
  color: red;
}

.navbarspan-larger:hover {
  cursor: grab;
  color: red;
}

@media screen and (max-width: 790px) {
  .navbarspan-larger {
    font-size: 20px;
  }
}
