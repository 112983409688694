.work-div {
  margin-top: 0px;
}

.backlog-container {
  display: flex;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 500px;
  margin-bottom: 10em;
}

.backlog-container * {
  background-color: #03071e;
}

.backlog-info {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  align-items: center;
}
.backlog-img {
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
}

.video {
  margin-left: 3em;
  height: 400px;
  width: 100%;
}
img {
  height: 100%;
  width: 100%;
}
ul {
  list-style-type: none;
}
li {
  font-size: 25px;
}
p {
  font-size: 13px;
}
h1 {
  font-size: 60px;
  color: #03071e;
}

.site-github {
  display: flex;
  height: 5em;
}

.site-github:hover {
  background-color: red;
}

.site-github-atag {
  font-size: 25px;
  transition: font-size 0.3s;
  text-decoration: none;
  position: relative;
  margin-left: -5em;

  margin-top: -7px;
  width: 4em;
  height: 1em;
  padding: 0.2em;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.5s;
}

.site-github-atag-octocat {
  width: 3em;
  height: 3em;
  transition: font-size 0.3s;
  text-decoration: none;
  margin-left: 3em;
  margin-top: -0.7em;
  position: absolute;
}

.arrimg {
  width: 2.5em;
  height: 1.8em;
  position: absolute;
  margin-left: 0em;
}
.site-github-atag:hover {
  color: white;
  text-decoration: none;
  background-color: #ff3258;
}

.visit-site {
  padding: 0.5em;
}

.italic {
  font-style: italic;
}

@media screen and (max-width: 600px) {
  .work-div {
    width: 100%;
  }
  li {
    font-size: 19px;
  }
  a {
    font-size: 15px;
  }
  .arrimg {
    width: 1.5em;
    height: 0.8em;
    position: absolute;
    margin-left: 0em;
  }

  .site-github {
    margin-top: 5px;
  }

  .backlog-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin-top: 10em;
    width: 100%;
  }

  .backlog-info {
    width: 100%;
    margin: 0 auto;
  }

  .backlog-img {
    width: 100%;
    margin: 0 auto;
    flex-grow: 5;
  }

  .video {
    margin-left: 0;
  }
}

@media screen and (max-width: 790px) {
  .work-div {
    width: 100%;
  }

  .backlog-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin-top: 10em;
    width: 100%;
  }

  .backlog-info {
    width: 100%;
    margin: 0 auto;
  }
  .backlog-img {
    width: 100%;
    margin: 0 auto;
    flex-grow: 5;
  }
  .video {
    margin-left: 0;
  }
  .site-github{
    margin-top: 15px;
  }
}
