@import url(https://fonts.googleapis.com/css2?family=Smooch&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
/* @font-face {
  font-family: 'Hk Grotesk';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/HKGrotesk-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Jost-Regular.ttf') format('woff');
} */


*{
  padding:0;
  margin: 0;
  box-sizing: inherit;
/*   
  background-color: #010606; */
  /* background-color: black; */
  color: white;

}

body {
  background-color: black;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size-small);
  font-family: var(--font-stack);
  font-weight: 400;
  color: var(--white-1);
  line-height: var(--line-height-normal);
  background: var(--black);
  overflow-x: hidden;
}
/* h1,
h2,
h3 {
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    line-height: var(--line-height-small);
    color: var(--white);
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.display-info {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 6;
  position: relative;
}

.intro-vid {
  width: 100%;
  height: 100%;
}

.video-only-div {
  width: 100%;
}

.video-data {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.name-work-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: RGB(0, 0, 0, 0);
  margin-top: -50%;
  margin-top: -40%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
.display-name {
  font-family: "Smooch", cursive;
  font-size: 100px;
  font-size: 130px;
  background-color: RGB(0, 0, 0, 0);

  z-index: 7;
}
.display-stack {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 700;
  z-index: 7;
}

.display-stack::before {
  content: "";
  width: 50px;
  height: 50px;
  background-color: #ff006e;
  border-top: 2px solid #ff006e;

  border-left: 2px solid #ff006e;
}

.display-stack::after {
  content: "";
  width: 100px;
  height: 100px;
  background-color: brown;
  border-top: 2px solid red;
  border-left: 2px solid red;
}

@media screen and (max-width: 800px) {
  .display-name {
    font-size: 50px;
  }
}


@media screen and (max-width: 790px) {
 
.intro-vid{
  height: 100%;
}
 
 }
.navbar {
  display: flex;
  height: 10vh;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  bottom: 0px;
  z-index: 9;
  position: fixed;
  background-color: red;
  background-color: #ff006e;
  background-color: #bdb2ff;
  background-color: rgba(106, 4, 15, 0.5);
}

.navbar.active {
  background-color: transparent;
}
.navbarspan {
  font-size: larger;
  color: #d00000;
  color: #ff006e;

  text-decoration: none;
  background-color: none;
  transition: font-size 0.5s;
}

.navbarspan-larger {
  font-size: 50px;
  background-color: none;
}

.navbarspan:hover {
  font-size: 40px;
  color: red;
}

.navbarspan-larger:hover {
  cursor: grab;
  color: red;
}

@media screen and (max-width: 790px) {
  .navbarspan-larger {
    font-size: 20px;
  }
}

.contact-container {
  margin-top: 70px;
}

.form-input-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 500px;
  margin: 0 auto;
}

.input-name,
.input-message,
.input-email {
  display: block;
  background-color: #343a40;
  padding: 1em;
  font-size: 15px;
}

.input-name,
.input-email {
  margin-top: 0.2em;
  width: 25em;
  height: 2em;
}

.question {
  margin-bottom: 2em;
  color: #2a9d8f;
  margin-top: 2em;
}

.input-message {
  margin-top: 0.2em;
  width: 25.1em;
  position: relative;
}
.submit-button {
  position: absolute;
  margin-left: 4em;
  margin-top: 1em;
  padding: 7px;
  font-size: 25px;
  background-color:black
}

.submit-button:hover {
  background-color: #2a9d8f;
}



.work-div {
  margin-top: 0px;
}

.backlog-container {
  display: flex;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 500px;
  margin-bottom: 10em;
}

.backlog-container * {
  background-color: #03071e;
}

.backlog-info {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  align-items: center;
}
.backlog-img {
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
}

.video {
  margin-left: 3em;
  height: 400px;
  width: 100%;
}
img {
  height: 100%;
  width: 100%;
}
ul {
  list-style-type: none;
}
li {
  font-size: 25px;
}
p {
  font-size: 13px;
}
h1 {
  font-size: 60px;
  color: #03071e;
}

.site-github {
  display: flex;
  height: 5em;
}

.site-github:hover {
  background-color: red;
}

.site-github-atag {
  font-size: 25px;
  transition: font-size 0.3s;
  text-decoration: none;
  position: relative;
  margin-left: -5em;

  margin-top: -7px;
  width: 4em;
  height: 1em;
  padding: 0.2em;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.5s;
}

.site-github-atag-octocat {
  width: 3em;
  height: 3em;
  transition: font-size 0.3s;
  text-decoration: none;
  margin-left: 3em;
  margin-top: -0.7em;
  position: absolute;
}

.arrimg {
  width: 2.5em;
  height: 1.8em;
  position: absolute;
  margin-left: 0em;
}
.site-github-atag:hover {
  color: white;
  text-decoration: none;
  background-color: #ff3258;
}

.visit-site {
  padding: 0.5em;
}

.italic {
  font-style: italic;
}

@media screen and (max-width: 600px) {
  .work-div {
    width: 100%;
  }
  li {
    font-size: 19px;
  }
  a {
    font-size: 15px;
  }
  .arrimg {
    width: 1.5em;
    height: 0.8em;
    position: absolute;
    margin-left: 0em;
  }

  .site-github {
    margin-top: 5px;
  }

  .backlog-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin-top: 10em;
    width: 100%;
  }

  .backlog-info {
    width: 100%;
    margin: 0 auto;
  }

  .backlog-img {
    width: 100%;
    margin: 0 auto;
    flex-grow: 5;
  }

  .video {
    margin-left: 0;
  }
}

@media screen and (max-width: 790px) {
  .work-div {
    width: 100%;
  }

  .backlog-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin-top: 10em;
    width: 100%;
  }

  .backlog-info {
    width: 100%;
    margin: 0 auto;
  }
  .backlog-img {
    width: 100%;
    margin: 0 auto;
    flex-grow: 5;
  }
  .video {
    margin-left: 0;
  }
  .site-github{
    margin-top: 15px;
  }
}


.about-me-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5em;
}

.about-me-details {
  width: 900px;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  color: black;
}

.about-me-p {
  margin-top: 2em;
  font-size: 20px;
  line-height: 2em;
  text-align: left;
  color: black;
}

.links-div {
  display: flex;
  margin-top: 12.5em;
}
.github-octocat {
  height: 5px;
}
.github-octo {
  height: 35px;
  width: 35px;
}
.linkedin-bug {
  height: 35px;
  width: 35px;
}

.github-linkedin-div {
  height: 20px;

  padding: 15px;
  background-color: #14213d;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.github-linkedin-div:hover {
  background-color: #06d6a0;
}
.copyright {
  margin: 0 auto;
}

.resume {
  color: red;
  font-size: 30px;
  text-decoration: none;

  margin: 0 auto;
}
.resume-button {
  background-color: black;
}

.resume:hover {
  background-color: #2a9d8f;
  color: white;
  padding: 3px;
}

.resume-icon {
  width: 100px;
}

@media screen and (max-width: 1000px) {
  .about-me-details {
    width: 600px;
  }

  .about-me-p {
    text-align: center;
    margin: center;
  }
}

@media screen and (max-width: 760px) {
  .about-me-details {
    width: 500px;
  }

  .about-me-p {
    text-align: center;
    margin: center;
  }
}
@media screen and (max-width: 500px) {
  .about-me-details {
    width: 400px;
  }

  .about-me-p {
    text-align: center;
    margin: center;
  }
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

